import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import '../css/innerheader.css';
import profile from "../images/profile.png"
import logoInner from "../images/logo_inner.png"
import headerIcon from "../images/header_icon.png"

import metamask from '../images/matamask.png'
import binancechain from '../images/binance-chain.png'
import math from '../images/math.png'
import tokenpct from '../images/tokenpct.png'
import trust from '../images/trust.png'
import walletc from '../images/wallet-c.png'

import logoIcon from "../images/logo-icon.png"
import logoText from "../images/logo-text-black.png"


class Headerinner extends Component 
{   
    constructor(props)
    {
        super(props);
        this.state = {connectModalShow: false}
    }
    componentDidMount()
    {  
        document.getElementById("sidebar_div").classList.remove('active');
        document.getElementById("sidebar_div").classList.remove('expand');
        document.body.classList.remove('collapsed');
    }
    showSidemenu()
    {
        // alert(1);
        document.getElementById("sidebar_div").classList.toggle('active');
        document.body.classList.toggle('collapsed');
        document.getElementById("sidebar_div").classList.remove('expand');
         
        
    }
  
    render() {
        let connectModalClose = () => this.setState({connectModalShow:false})
    // const {location} = this.props;


		return (
           <div>
               <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
            <div className="container-fluid px-0">
                <div className="mr-2" onClick={this.showSidemenu}><img src={headerIcon} className="cursor_pointer"/></div>
                <Link to="/Home">
                    <span className="logo_full">
                    <img src={logoIcon} className="logo_icon" id="logo_inner"/>
                    <img src={logoText} className="logo_text" id="logo_inner"/>

                    </span>
                    {/* <span className="logo_inner" id="logo_inner"></span> */}

                    </Link>
                
                <ul className="navbar-nav ml-auto header_nav">
                    <li className="nav-item">
                    <button className="get-started-btn btn_inner" onClick={() => this.setState({connectModalShow:true})}>CONNECT</button>
                    </li>
                    <li className="nav-item">
                    <a href="/"><img src={profile} /></a>
                    </li>
                    
                
                </ul>
     
  </div>
</nav>

{/* connect */}
                            
<Modal
                                show = {this.state.connectModalShow}
                                onHide = {connectModalClose}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                    Connect to a wallet
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>                                  
                                   <div>                                  
                                      
                                           <ul className="connect_ul">
                                               <li>
                                                   <span className="text_purple font_weight_600">Metamask</span>
                                                   <img src={metamask} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_purple font_weight_600">TrustWallet</span>
                                                   <img src={trust} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_purple font_weight_600">MathWallet</span>
                                                   <img src={math} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_purple font_weight_600">TokenPocket</span>
                                                   <img src={tokenpct} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_purple font_weight_600">WalletConnect</span>
                                                   <img src={walletc} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_purple font_weight_600">Binance Chain Wallet</span>
                                                   <img src={binancechain} className="dropdown_img mr-0" />

                                               </li>
                                           </ul>
                                           <p className="text-center mb-0">
                                               <span><i className="fa fa-question-circle mr-2" aria-hidden="true"></i></span>
                                               <span className="text_purple font_18 font_weight_600">Learn how to connect</span>
                                           </p>
                                   </div>
                                  
                                </Modal.Body>
                              
                                </Modal>


           </div>
        );
    }
}



export default Headerinner