import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import { Button, Collapse, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/sidebar.css';
import home from "../images/home-ico.png"
import grap from "../images/grap.png"
import pool from "../images/pool.png"

import farms from "../images/farm-ico.png"
import down from "../images/down.png"
import info from "../images/info.png"
import more from "../images/more.png"

import sidebar from "../images/side-bar.png"
import twitter from "../images/twittr.png"
import telegram from "../images/telegram.png"
import dark from "../images/dark.png"
import bright from "../images/bright.png"
import globe from "../images/globe.png"

import setting from "../images/setting.png"

import logoInner from "../images/logo_inner.png"
import logoWhite from "../images/logo-white.png"



class Sidebar extends Component 
{   
    // showAccordion1()
    // {
    //     document.getElementById("sidebar_ul_1").classList.toggle('active');
    
        
    // }

    showSidemenu()
    {
        // alert(1);
        document.getElementById("sidebar_div").classList.add('expand');
        document.body.classList.remove('collapsed');
         
        
    }
    darkTheme()
    {
        document.body.classList.add('dark_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoWhite;
    }
    lightTheme()
    {
        document.body.classList.remove('dark_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoInner;
    }
    constructor(props, context) {
		super(props, context);

		this.state = {
			open: false,
			openone: false,
			opentwo: false,
            location:false
		};
	}
    render() {
        const { open, openone, opentwo } = this.state;
        const {location} = this.props;


		return (
           <div>             

{/* sidebar */}
<div className="sidebar_div" id="sidebar_div">
    <ul className="sidebar_ul">
         {location && location === 'uniswapapp' && ( <li className="sidebar_li active">
            <div className="d-flex align-items-center">
                <img src={home} className="sidebar_icon mr-2" />
                <span className="sidebar_text"><Link to="/uniswapapp">Home</Link></span>
            </div>
        </li>)}
            {location != 'uniswapapp' && (<li className="sidebar_li">
            <div className="d-flex align-items-center">
                <img src={home} className="sidebar_icon mr-2" />
                <span className="sidebar_text"><Link to="/uniswapapp">Home</Link></span>
            </div>
        </li>)}
        {/* <li className="sidebar_li active">
            <div className="d-flex align-items-center">
                <img src={home} className="sidebar_icon mr-2" />
                <span className="sidebar_text"><Link to="/uniswapapp">Home</Link></span>
            </div>
        </li> */}
        <li className="sidebar_li" onClick={this.showSidemenu}>
        <span
					onClick={() => this.setState({ open: !open })}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					<div className="d-flex align-items-center" onClick={this.showAccordion1}>
                <img src={grap} className="sidebar_icon mr-2" />
                <span className="sidebar_text sidebar_text_1">Trade<img src={down} className="down_icon" /></span>
            </div>
        </span>
				<Collapse in={this.state.open}>
					<div id="example-collapse-text">
					<ul className="sidebar_ul sidebar_inner">
                    {location && location === 'Exchange' && (
                        <li className="sidebar_li active">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Exchange">Exchange</Link></span>
                        </div>
                        </li>
                    )}
                    {location != 'Exchange' && (
                         <li className="sidebar_li">
                         <div className="d-flex align-items-center">
                         <span className="sidebar_text">
                         <Link to="/Exchange">Exchange</Link></span>
                         </div>
                         </li>
                    )}
                     {location && location === 'Liquidity' && (
                        <li className="sidebar_li active">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text"> <Link to="/Liquidity">Liquidity</Link></span>
                        </div>
                        </li>
                     )}
                     {location != 'Liquidity' && (
                          <li className="sidebar_li">
                          <div className="d-flex align-items-center">
                          <span className="sidebar_text"> <Link to="/Liquidity">Liquidity</Link></span>
                          </div>
                          </li>
                     )}
                    </ul>
          </div>
				</Collapse>
        </li>
        {location && location === 'Farms' && (
        <li className="sidebar_li active">
            <div className="d-flex align-items-center">
                <img src={farms} className="sidebar_icon mr-2" />
                <span className="sidebar_text"><Link to="/Farms">Farms</Link></span>
            </div>
        </li>
        )}
        {location != 'Farms' && (
            <li className="sidebar_li">
            <div className="d-flex align-items-center">
                <img src={farms} className="sidebar_icon mr-2" />
                <span className="sidebar_text"><Link to="/Farms">Farms</Link></span>
            </div>
        </li>
        )}
        {location && location === 'Pools' && (
        <li className="sidebar_li active">
            <div className="d-flex align-items-center">
                <img src={pool} className="sidebar_icon mr-2" />
                <span className="sidebar_text"><Link to="/Pools">Pools</Link></span>
            </div>
        </li>
        )}
         {location != 'Pools' && (
              <li className="sidebar_li">
              <div className="d-flex align-items-center">
                  <img src={pool} className="sidebar_icon mr-2" />
                  <span className="sidebar_text"><Link to="/Pools">Pools</Link></span>
              </div>
          </li>
         )}

<li className="sidebar_li" onClick={this.showSidemenu}>
        <span
					onClick={() => this.setState({ openone: !openone })}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					<div className="d-flex align-items-center" onClick={this.showAccordion1}>
                <img src={info} className="sidebar_icon mr-2" />
                <span className="sidebar_text sidebar_text_1">Info<img src={down} className="down_icon" /></span>
            </div>
        </span>
				<Collapse in={this.state.openone}>
					<div id="example-collapse-text">
					<ul className="sidebar_ul sidebar_inner">
                   
                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Swap">PancakeSwap</Link></span>
                        </div>
                        </li>
                        
                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Home">CoinGecko</Link></span>
                        </div>
                        </li>
                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Home">CoinMarketCap</Link></span>
                        </div>
                        </li>

                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Home">AstroTools</Link></span>
                        </div>
                        </li>
                   
                    </ul>
          </div>
				</Collapse>
        </li>


        <li className="sidebar_li" onClick={this.showSidemenu}>
        <span
					onClick={() => this.setState({ opentwo: !opentwo })}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					<div className="d-flex align-items-center" onClick={this.showAccordion1}>
                <img src={more} className="sidebar_icon mr-2" />
                <span className="sidebar_text sidebar_text_1">More<img src={down} className="down_icon" /></span>
            </div>
        </span>
				<Collapse in={this.state.opentwo}>
					<div id="example-collapse-text">
					<ul className="sidebar_ul sidebar_inner">
                   
                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Home">Github</Link></span>
                        </div>
                        </li>
                        
                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Home">Docs</Link></span>
                        </div>
                        </li>
                        <li className="sidebar_li">
                        <div className="d-flex align-items-center">
                        <span className="sidebar_text">
                        <Link to="/Home">Blog</Link></span>
                        </div>
                        </li>
                        
                   
                    </ul>
          </div>
				</Collapse>
        </li>
        
        
    </ul>

    <div className="sidebar_bottom">
    <li className="sidebar_li side_bot_li" onClick={this.showSidemenu}>
            <div className="d-flex align-items-center">
                <img src={setting} className="sidebar_icon" />                
            </div>
        </li>
        <div className="row mx-0 side_bot_li_row">
            <div className="d-flex mb-3 w-100 align-items-center">
                <img src={sidebar} />
                <span className="text_purple px-2">$17.731</span>
                <span className="end_content">
                <img src={twitter} className="pr-2" />
                <img src={telegram} className="" />

                </span>

            </div>
            <div className="d-flex w-100 align-items-center">
                <span>
                <img src={dark} onClick={this.darkTheme} className="cursor_pointer" />
                <span className="px-2">/</span>
                <img src={bright} onClick={this.lightTheme}  className="cursor_pointer"/>
                </span>
                <span>
                <span className="end_content">
                <img src={globe}/>
                <span className="text_purple pl-2 dr_span">
                <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="pl-2">
                EN
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item>English</Dropdown.Item>
                <Dropdown.Item>Chinese</Dropdown.Item>
                <Dropdown.Item>Korean</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                </span>

                </span>
                </span>

            </div>
        </div>

    </div>

</div>



           </div>
        );
    }
}



export default Sidebar