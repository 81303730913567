import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

import { Tabs, Tab } from 'react-bootstrap';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import '../css/styles.css';

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import CountUp from 'react-countup';
import { Timeline } from 'react-twitter-widgets'
import coin from "../images/coin.png"

import pgm1 from "../images/pgm1.png"
import pgm2 from "../images/pgm2.png"
import pgm3 from "../images/pgm3.png"
import pgm4 from "../images/pgm4.png"

import token1 from "../images/token-1.png"
import token2 from "../images/token-2.png"
import token3 from "../images/token-3.png"
import token4 from "../images/token-4.png"
import token5 from "../images/token-5.png"
import token6 from "../images/token-6.png"
import token7 from "../images/token-7.png"

import metamask from '../images/matamask.png'
import binancechain from '../images/binance-chain.png'
import math from '../images/math.png'
import tokenpct from '../images/tokenpct.png'
import trust from '../images/trust.png'
import walletc from '../images/wallet-c.png'

import testimonial from "../images/testimonial.png"

import logo from "../images/logo.png"





class Landing extends Component 
{

    state= {
        responsive:{
            0: {
                items: 2,
            },
            450: {
                items: 3,
            },
            600: {
                items: 5,
            },
            1000: {
                items: 7,
            },
        },

        
    }
    render() {
        
      const location = this.props.location.pathname.split('/')[1];
    //   let connectModalClose = () => this.setState({connectModalShow:false})
    //   console.log("location",location);
       
		return (
            <div>
                <Header location={location} />
                <div id="whole_sec">
                <section id="banner">
                    <div className="banner_bg">
                    <div className="container container_custom">
                        <div className="banner_text_padding">
                        <div className="logo_banner"></div>
                        <p className="banner_sub_text">THE EVOLVING COMMUNITY OF THRIVING DEFI ENVIRONMENT</p>
                        <p className="text-white font_land_des">Empowering developers, liquidity providers, and traders to participate in an open crypto asset marketplace and community-driven platform.</p>
                        <div className="text-center pt-2 btn_sec">
                        {/* <Link to="/uniswapapp" className="btn_gradient mr-3">CONNECT WALLET</Link> */}
                        <a href="https://app.tostswap.finance/" className="btn_gradient">USE APP</a>


                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                {/* <section id="sec_2">
                    <div className="container container_custom">
                        <div className="card card_grey border-0 mt_m_100">
                            <div className="card-body">
                                <p className="text-grey">Recent Swap</p>
                                <input type="search" className="input_black empty" placeholder="&#xF002;"/>
                                <div className="table-responsive mt-4">
                                <table className="table table_style_1">
                                <thead>
                                    <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Token</th>
                                    <th scope="col">Liquidity</th>
                                    <th scope="col">Volume</th>
                                    <th scope="col">Price Change (24 Hours)</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                    </div>
                                        </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                    <tr>
                                    <td>2</td>
                                    <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                    </div>
                                    </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                    <tr>
                                    <td>3</td>
                                    <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                    </div>
                                    </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                    <tr>
                                    <td>4</td>
                                    <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                    </div>
                                    </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                    <tr>
                                    <td>5</td>
                                    <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                   </div>
                                    </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                    <tr>
                                    <td>6</td>
                                    <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                    </div>
                                    </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                    <tr>
                                    <td>7</td>
                                    <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={coin} alt="Coin" className="coin_icon mr-2" />
                                    <span className="coin_name">Wrapped BNB</span>
                                    </div>
                                    </td>
                                    <td>$95,426,624</td>
                                    <td>$65,584,045</td>
                                    <td><span className="text_green">+2.16%</span></td>
                                    </tr>
                                </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                        </div>
                </section> */}
                </div>

                {/* <section id="sec_3" className="pt-5">
                    <div className="container container_custom">
                        <div className="text-center">
                    <h3 className="title_text">COMPARISON</h3>
                        <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        </div>
                        <div className="row mt-compare">
                            <div className="col-xl-9 mx-auto">
                                <div className="row">
                                <div className="col-12 col-lg-4 px-3 px-lg-0">
                            <div className="card compare_card compare_card_1">
                                <div className="card-body px-0">
                                    <p className="font_25 mb-0 text-center text-uppercase mb-3 fw_600 overlay_txt">AAVE.com</p>
                                    <div className="div_overlay_1">
                                    <p className="text-center d-flex align-items-center justify-content-center mb-0">
                                        <span className="font_30">0.999</span>
                                        <span className="font_20 pl-2">ETH/USDT</span>
                                    </p>
                                    </div>
                                    <p className="font_15 pl-3 pr-4 mb-0">Lorem ipsum, or lipsum as it is sometimes known,is dummy text used in laying out .</p>
                                    <ul className="ul_style_1 font_15 pl-5 pr-4">
                                        <li>Lorem ipsum, or lipsum as it is sometimes .Lorem ipsum, or lipsum as it is sometimes </li>
                                        
                                        <li>Lorem ipsum, or lipsum as it is sometimes .Lorem ipsum, or lipsum as it is sometimes </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-lg-4 px-3 px-lg-0 card_2_div">
                            <div className="card compare_card_hightlight compare_card_2">
                                <div className="card-body px-0">
                                    <p className="font_25 mb-0 text-center mb-3 px-3 fw_600">TOST SWAP</p>
                                    <div className="div_overlay">
                                    <p className="text-center d-flex align-items-center justify-content-center px-3 mb-0">
                                        <span className="font_30">1.0000</span>
                                        <span className="font_20 pl-2">ETH/USDT</span>
                                    </p>
                                    </div>
                                    <div className="px-4">
                                    <p className="font_15 mb-0">Lorem ipsum, or lipsum as it is sometimes known,is dummy text used in laying out .</p>
                                    <ul className="ul_style_1 font_15">
                                        <li>Lorem ipsum, or lipsum as it is sometimes .Lorem ipsum, or lipsum as it is sometimes </li>
                                        
                                        <li>Lorem ipsum, or lipsum as it is sometimes .Lorem ipsum, or lipsum as it is sometimes </li>

                                        <li>Lorem ipsum, or lipsum as it is sometimes</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-lg-4 px-3 px-lg-0">
                            <div className="card compare_card compare_card_3">
                                <div className="card-body px-0">
                                    <p className="font_25 mb-0 text-center text-uppercase mb-3 fw_600 overlay_txt">1inch.com</p>
                                    <div className="div_overlay_1">
                                   <p className="text-center d-flex align-items-center justify-content-center mb-0">
                                        <span className="font_30">0.998</span>
                                        <span className="font_20 pl-2">ETH/USDT</span>
                                    </p>
                                    </div>
                                    <p className="font_15 pl-4 px-3 mb-0">Lorem ipsum, or lipsum as it is sometimes known,is dummy text used in laying out .</p>
                                    <ul className="ul_style_1 font_15 pl-5 pr-3">
                                        <li>Lorem ipsum, or lipsum as it is sometimes .Lorem ipsum, or lipsum as it is sometimes </li>
                                        
                                        <li>Lorem ipsum, or lipsum as it is sometimes .Lorem ipsum, or lipsum as it is sometimes </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                                </div>
                            </div>
                         
                        </div>
                        </div>
                       
                      
                </section> */}

<div className="bg_purple_layer pt-5">
<div className="container container_custom">
    <div className="row">
        <div className="col-12 col-md-6 col-lg-3">
            <p className="counter_text">$<span>
                <CountUp
                start={0}
                end={83}
                duration={2.75}>
                </CountUp>
                </span>B+</p>
            <p className="counter_subtext">All Time Volume</p>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
            <p className="counter_text">
            <CountUp
                start={0}
                end={72}
                duration={2.75}>
            </CountUp>
                <span>K+</span></p>
            <p className="counter_subtext">Liquidity Providers</p>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
            <p className="counter_text">
            <CountUp
                start={0}
                end={29}
                duration={2.75}>
            </CountUp>
            <span>M+</span></p>
            <p className="counter_subtext">All Time Trades</p>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
            <p className="counter_text">
            <CountUp
                start={0}
                end={200}
                duration={2.75}>
            </CountUp>
            <span>+</span></p>
            <p className="counter_subtext">Defi Integrations</p>
        </div>

    </div>
    
</div>

</div>
{/* 
                <section id="sec_4" className="pt-5">
                    <div className="container container_custom">
                        <div className="text-center">
                    <h3 className="title_text">Get involved through our grant programs</h3>
                        <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-md-6 mb-4">
                                <div className="card card_pgm">
                                    <div className="card-body">
                                    <img src={pgm1} alt="Micro-lending" className="mb-4"/>
                                    <p className="font_25 text-white mb-3">Micro-Lending</p>
                                    <p className="font_16 text_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                                    <a href="#" className="purple_link font_18 float-right">ReadMore</a>
                                    </div>

                                </div>

                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <div className="card card_pgm">
                                    <div className="card-body">
                                    <img src={pgm2} alt="Micro-lending" className="mb-4"/>
                                    <p className="font_25 text-white mb-3">Under collateralized Loans</p>
                                    <p className="font_16 text_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                                    <a href="#" className="purple_link font_18 float-right">ReadMore</a>
                                    </div>

                                </div>

                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <div className="card card_pgm">
                                    <div className="card-body">
                                    <img src={pgm3} alt="Micro-lending" className="mb-4"/>
                                    <p className="font_25 text-white mb-3">Credit Delegation</p>
                                    <p className="font_16 text_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                                    <a href="#" className="purple_link font_18 float-right">ReadMore</a>
                                    </div>

                                </div>

                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <div className="card card_pgm">
                                    <div className="card-body">
                                    <img src={pgm4} alt="Micro-lending" className="mb-4"/>
                                    <p className="font_25 text-white mb-3">Credit Default Swaps</p>
                                    <p className="font_16 text_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                                    <a href="#" className="purple_link font_18 float-right">ReadMore</a>
                                    </div>

                                </div>

                            </div>
                        </div>
                        </div>
                </section> */}

                {/* <section id="sec_5" className="pt-5">
                    <div className="bg_purple_light">
                    <div className="container container_custom">
                        <div className="text-center">
                    <h3 className="title_text_1 text-blak">Capitalization Weighted Defi And Altcoin Index</h3>
                        <p className="text-blak">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                        </div>
                        <div className="slider_sec_1 my-5">
                        <OwlCarousel items={3}  
                        className="owl-theme"  
                        loop  
                        nav={true} 
                        margin={20} autoplay ={true} responsive={this.state.responsive} dots={false}>  
                        <div>
                        <a className="coin_a">
                        <img src={token1} alt="" /></a>
                        <p className="slider_text_1 mb-0">Aave</p>
                        <p className="slider_text_2 mb-0">(AAVE)</p>
                        <p className="slider_text_3 mb-0">10%</p>
                        </div>     

                        <div>
                        <a className="coin_a">
                        <img src={token2} alt="" /></a>
                        <p className="slider_text_1 mb-0">Uniswap</p>
                        <p className="slider_text_2 mb-0">(UNI)</p>
                        <p className="slider_text_3 mb-0">10%</p>
                        </div>   

                        <div>
                        <a className="coin_a">
                        <img src={token3} alt="" /></a>
                        <p className="slider_text_1 mb-0">1inch</p>
                        <p className="slider_text_2 mb-0">(1inch)</p>
                        <p className="slider_text_3 mb-0">5%</p>
                        </div>   

                        <div>
                        <a className="coin_a">
                        <img src={token4} alt="" /></a>
                        <p className="slider_text_1 mb-0">OX</p>
                        <p className="slider_text_2 mb-0">(ZRX)</p>
                        <p className="slider_text_3 mb-0">5%</p>
                        </div> 

                        <div>
                        <a className="coin_a">
                        <img src={token5} alt="" /></a>
                        <p className="slider_text_1 mb-0">Synthetix</p>
                        <p className="slider_text_2 mb-0">(SNX)</p>
                        <p className="slider_text_3 mb-0">7.5%</p>
                        </div> 

                        <div>
                        <a className="coin_a">
                        <img src={token6} alt="" /></a>
                        <p className="slider_text_1 mb-0">Balancer</p>
                        <p className="slider_text_2 mb-0">(BAL)</p>
                        <p className="slider_text_3 mb-0">10%</p>
                        </div> 

                        <div>
                        <a className="coin_a">
                        <img src={token7} alt="" /></a>
                        <p className="slider_text_1 mb-0">Compond</p>
                        <p className="slider_text_2 mb-0">(COMP)</p>
                        <p className="slider_text_3 mb-0">5%</p>
                        </div>    
                       
                        </OwlCarousel>
                        </div>
                        </div>
                        </div>
                        </section>    */}
                        <section id="info_section" className="py-5">
                            <div className="container container_custom">
                                <div className="text-center">
                            <h3 className="title_text">Latest TOST SWAP Info</h3>
                                <p className="text-white">Announcements and Updates</p>
                               
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-8 col-lg-7 col-xl-6">
                                    <div className="card card_tab mt-5 border-0">
                                <div className="card-body">
                                <Timeline
                                dataSource={{
                                    sourceType: 'profile',
                                    screenName: 'CommunityTost'
                                }}
                                options={{
                                    height: '450',
                                    chrome: "noheader, nofooter",
                                    
                                }}
                                />
                                </div>
                                </div>
                                    </div>
                                    {/* <div className="col-12 col-md-6">
                                    <div className="card card_tab mt-5 border-0">
                                <div className="card-body">
                               
                                </div>
                                </div>  
                                    </div> */}
                                </div>
                               
                                {/* <div className="card card_tab mt-5 border-0">
                                <div className="card-body px-0 py-0">
                                <div className="news_tab">
                                <Tabs id="controlled-tab-example">
                               
                                <Tab eventKey="news" title="NEWS">
                                <div className="">
                                <OwlCarousel items={1}  
                                className="owl-theme"  
                                loop  
                                nav={true} 
                                margin={0} autoplay ={true}  dots={false}> 
                                <div>

                                <div>
                                <div className="row">

                                <div className="col-12 col-md-5 col-xl-5">
                              
                                <div className="test_bg"></div>
                                </div>
                                <div className="col-12 col-md-7 col-xl-7 px-4">
                                    <div className="center_text">
                                        <p className="news_title_1">EARN EASY TOKEN</p>
                                        <p className="news_title_2">Yield Farming</p>
                                        <p className="news_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>


                                    </div>
                                </div>
                                </div>
                                </div>

                                </div>
                                <div>

                                <div className="row">

                                <div className="col-12 col-md-5 col-xl-5">
                               
                                <div className="test_bg"></div>
                                </div>
                                <div className="col-12 col-md-7 col-xl-7 px-4">
                                    <div className="center_text">
                                        <p className="news_title_1">EARN EASY TOKEN</p>
                                        <p className="news_title_2">Yield Farming</p>
                                        <p className="news_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>


                                    </div>
                                </div>
                                </div>

                                </div>
                                </OwlCarousel>
                                </div>
                                <div className="tab_footer">
                                    <div className="footer_con">
                                    <p className="footer_heading">DUAL FARMING WITH NATIVE STAKING</p>
                                    <p className="footer_text">News-Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                </Tab>

                              
                                <Tab eventKey="testimonials" title="TESTIMONIAL">
                                <div className="">
                                <OwlCarousel items={1}  
                                className="owl-theme"  
                                loop  
                                nav={true} 
                                margin={0} autoplay ={true}  dots={false}>  
                                <div>

                                <div className="row">

                                <div className="col-12 col-md-5 col-xl-5">
                               
                                <div className="test_bg"></div>
                                </div>
                                <div className="col-12 col-md-7 col-xl-7 px-4">
                                    <div className="center_text">
                                        <p className="news_title_1">EARN EASY TOKEN TESTIMONIAL</p>
                                        <p className="news_title_2">Yield Farming</p>
                                        <p className="news_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>


                                    </div>
                                </div>
                                </div>

                                </div>

                                <div>

                                <div className="row">

                                <div className="col-12 col-md-5 col-xl-5">
                               
                                <div className="test_bg"></div>
                                </div>
                                <div className="col-12 col-md-7 col-xl-7 px-4">
                                    <div className="center_text">
                                        <p className="news_title_1">EARN EASY TESTIMONIAL</p>
                                        <p className="news_title_2">Yield Farming</p>
                                        <p className="news_content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>


                                    </div>
                                </div>
                                </div>

                                </div>
                                </OwlCarousel>
                                </div>
                                <div className="tab_footer">
                                    <div className="footer_con">
                                    <p className="footer_heading">DUAL FARMING WITH NATIVE STAKING</p>
                                    <p className="footer_text">Testimonials-Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                </Tab>
                                </Tabs>
                                </div>
                                </div>
                                </div> */}
                                </div>
                               {/* connect */}
                            
                                {/* <Modal
                                className="modal_dark"
                                show = {this.state.connectModalShow}
                                onHide = {connectModalClose}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                    Connect to a wallet
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>                                  
                                   <div>                                  
                                      
                                           <ul className="connect_ul">
                                               <li>
                                                   <span className="text_black_1 font_weight_600">Metamask</span>
                                                   <img src={metamask} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_black_1 font_weight_600">TrustWallet</span>
                                                   <img src={trust} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_black_1 font_weight_600">MathWallet</span>
                                                   <img src={math} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_black_1 font_weight_600">TokenPocket</span>
                                                   <img src={tokenpct} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_black_1 font_weight_600">WalletConnect</span>
                                                   <img src={walletc} className="dropdown_img mr-0" />

                                               </li>
                                               <li>
                                                   <span className="text_black_1 font_weight_600">Binance Chain Wallet</span>
                                                   <img src={binancechain} className="dropdown_img mr-0" />

                                               </li>
                                           </ul>
                                           <p className="text-center mb-0">
                                               <span><i className="fa fa-question-circle mr-2" aria-hidden="true"></i></span>
                                               <span className="text_purple font_18 font_weight_600">Learn how to connect</span>
                                           </p>
                                   </div>
                                  
                                </Modal.Body>
                              
                                </Modal> */}
                             </section>
                             <Footer />
            </div>
        )
    }
}

export default Landing