import React, { Component } from 'react';
import Headerinner from './Headerinner';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import { Button, Collapse } from 'react-bootstrap';

// import '../css/footer.css';
import '../css/pool.css';

import pink from "../images/pin.png"

import boximghome from '../images/boximg-home.png'
import ok from "../images/ok.png"
import arrdown from "../images/arrow-down.png"
import questioncircle from "../images/question_circle.svg"


class Pool extends Component 
{ 
    constructor(props, context) {
		super(props, context);

		this.state = {
			open: false,
            openone:false,
            opentwo:false,
            openthree:false,
            openfour:false,
            openfive:false,
            opensix:false,
            openseven:false,
            openeight:false,
            opennine:false,
            openten:false,
            openeleven:false,


		};
	}

    showActive()
    {
        document.getElementById("inactive_btn").classList.remove('active');
        document.getElementById("active_btn").classList.add('active');
        // document.getElementById("add_liquidity").classList.remove('d-block');
        document.getElementById("inactive_content").classList.remove('d-block');
        document.getElementById("inactive_content").classList.add('d-none');
        document.getElementById("active_content").classList.remove('d-none');
        document.getElementById("active_content").classList.add('d-block');

    }
    showInactive()
    {
        document.getElementById("active_btn").classList.remove('active');
        document.getElementById("inactive_btn").classList.add('active');
        // document.getElementById("add_liquidity").classList.remove('d-block');
        document.getElementById("active_content").classList.add('d-none');
        document.getElementById("active_content").classList.remove('d-block');

        document.getElementById("inactive_content").classList.add('d-block');
        document.getElementById("inactive_content").classList.remove('d-none');


    }
   
    render() {
        const { open,openone,opentwo,openthree,openfour,openfive, opensix, openseven,openeight, opennine, openten, openeleven } = this.state;
      // console.log(this.props.location.pathname);
      const location = this.props.location.pathname.split('/')[1];

		return (
            <div>
               <Headerinner />
               <div className="row mx-0 main_row">
                   <div className="col-12 col-lg-3 col-lg-3-sidebar px-0 sidebar_col">
                    <Sidebar location={location}/>
                   </div>
                   <div className="col-12 col-lg-9 col-lg-9-custom main_col mt-lg-custom">
                       <div className="container container_custom py-5">
                        <div className="row mx-0">
                            <div className="col-12 col-lg-5 flex_end_text">
                                <h1 className="pool_heading">Pink Pool</h1>
                                <p className="pool_desc mb-0">Stake CAKE to earn new tokens.<br />
                                    You can unstake at any time.<br />
                                    Rewards are calculated per block.</p>
                            </div>
                            <div className="col-12 col-lg-7 flex_end_image">
                            <img src={pink} className="img-fluid"/>
                            </div>
                        </div>
                        <div className="nav_sec">
                        <span>
                      
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                            <label className="custom-control-label" for="customSwitch1">Staked only</label>
                            </div>
                        </span>
                        <span>
                            <div className="tab_sec_custom">
                            <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-secondary active" id="active_btn"  onClick={this.showActive}>Active</button>
                        <button type="button" className="btn btn-secondary"  id="inactive_btn" onClick={this.showInactive}>Inactive</button>
                       
                        </div>
                            </div>
                            
                        </span>
                        </div>

                        <div className="tab_content_custom">
                                <div className="active_content" id="active_content">
                                    <div className="py-4">
                                    <div className="row mx-0 justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price">Dough Pool</h1>
                                                    <p className="pool_price_subtext">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ open: !open })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.open}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price">Dough Pool</h1>
                                                    <p className="pool_price_subtext">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openone: !openone })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openone}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price">Dough Pool</h1>
                                                    <p className="pool_price_subtext">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ opentwo: !opentwo })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.opentwo}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price">Dough Pool</h1>
                                                    <p className="pool_price_subtext">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openthree: !openthree })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openthree}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price">Dough Pool</h1>
                                                    <p className="pool_price_subtext">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openfour: !openfour })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openfour}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price">Dough Pool</h1>
                                                    <p className="pool_price_subtext">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openfive: !openfive })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openfive}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">137.29%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <h2 className="pool_head">Your Project?<span role="img" aria-label="eyes">👀   👀</span></h2>
                                                    <img src={questioncircle} className="mb-2"/>
                                                    <h1 className="pool_price">???</h1>
                                                    <p className="pool_price_subtext">Create a pool for your token</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_outline">Apply now</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">??</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool text-sm-right">??? DOUGH</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-12">
                                                                        <badge className="badge_purple">
                                                                        <i className="fa fa-users mr-2 user_grp_icon" aria-hidden="true"></i> 
                                                                            <span>Community</span>
                                                                        </badge>
                                                                    </div>
                                                              
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inactive_content" id="inactive_content">
                                <div className="py-4">
                                <div className="row mx-0 justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool_inactive">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="img_inactive"></div>
                                                    <h2 className="pool_head_disable">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price_disable">0.0000</h1>
                                                    <p className="pool_price_subtext_disable">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">-</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon_disable mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ opensix: !opensix })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.opensix}>
                                                        <div id="example-collapse-text">
                                                        
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Total</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">230.552</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool_inactive">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="img_inactive"></div>
                                                    <h2 className="pool_head_disable">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price_disable">0.0000</h1>
                                                    <p className="pool_price_subtext_disable">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">-</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon_disable mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openseven: !openseven })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openseven}>
                                                        <div id="example-collapse-text">
                                                        
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Total</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">230.552</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool_inactive">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="img_inactive"></div>
                                                    <h2 className="pool_head_disable">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price_disable">0.0000</h1>
                                                    <p className="pool_price_subtext_disable">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">-</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon_disable mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openeight: !openeight })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openeight}>
                                                        <div id="example-collapse-text">
                                                        
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Total</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">230.552</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool_inactive">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="img_inactive"></div>
                                                    <h2 className="pool_head_disable">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price_disable">0.0000</h1>
                                                    <p className="pool_price_subtext_disable">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">-</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon_disable mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ opennine: !opennine })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.opennine}>
                                                        <div id="example-collapse-text">
                                                        
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Total</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">230.552</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool_inactive">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="img_inactive"></div>
                                                    <h2 className="pool_head_disable">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price_disable">0.0000</h1>
                                                    <p className="pool_price_subtext_disable">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">-</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon_disable mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openten: !openten })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openten}>
                                                        <div id="example-collapse-text">
                                                        
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Total</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">230.552</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool_inactive">
                                            <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="img_inactive"></div>
                                                    <h2 className="pool_head_disable">Dough Pool</h2>
                                                    <img src={boximghome} className="mb-2"/>
                                                    <h1 className="pool_price_disable">0.0000</h1>
                                                    <p className="pool_price_subtext_disable">Nut Earned</p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1">Unlock Wallet</Link>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">-</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon_disable mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Your Stake</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">0.000</p>
                                                                </div>
                                                                </div>
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <badge className="badge_purple">
                                                                            <img src={ok}  className="mr-2" />
                                                                            <span>Core</span>
                                                                        </badge>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                    <span
                                                                    onClick={() => this.setState({ openeleven: !openeleven })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-end">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openeleven}>
                                                        <div id="example-collapse-text">
                                                        
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable">
                                                                <span className="font_icon mr-2"><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                                                                Total</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool_disable text-sm-right">230.552</p>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                </div>
                            </div>
                    
                        
                    </div>
                   </div>
               </div>
            </div>
        )
        }
}

export default Pool