import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button } from 'react-bootstrap';

import '../css/footer.css';
import footerIcon1 from "../images/footer_icon_1.png"
import footerIcon2 from "../images/footer_icon_2.png"
import footerIcon3 from "../images/footer_icon_3.png"
import footerIcon4 from "../images/footer_icon_4.png"
import footerIcon5 from "../images/footer_icon_5.png"

import buttomimage from "../images/btn-submit.png"


class Footer extends Component 
{ 
    constructor(props)
    {
        super(props);
        this.state = {disclaimerModalShow: false,
                     }
        


    }
   
    render() {
        let disclaimerModalClose = () => this.setState({disclaimerModalShow:false})
		return (
           <div>
               <div className="footer">
               <div className="footer_bg_purple">
                   <div className="footer_sec">
                   <div className="container conatiner_custom px-0">
                       <div className="row mx-0">
                           <div className="col-12 col-md-7 col-lg-8">
                            <p className="text-white">TOST community is building a tremendous, decentralized trading platform for the future of crypto assets. Join us!</p>
                           </div>
                           <div className="col-12 col-md-5 col-lg-4">
                               <div className="d-flex">
                                   <ul className="footer_ul">
                                       <li>
                                           <a href="https://telegram.me/tostswap" className="text-white"><span>Telegram</span></a>
                                       </li>
                                       <li>
                                           <a href="#" className="text-white"><span>Instagram</span></a>
                                       </li>
                                       <li>
                                           <a className="text-white cursor_pointer" onClick={() => this.setState({disclaimerModalShow:true})}><span>Disclaimer</span></a>
                                       </li>
                                   </ul>
                               </div>
                               </div>
                       </div>
                       {/* <div className="row mx-0">
                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><img src={footerIcon1} className="mr-2"/><span>Discord</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon2} className="mr-2"/><span>GitHub</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon3} className="mr-2"/><span>Twitter</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon4} className="mr-2"/><span>Telegram</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon5} className="mr-2"/><span>Instagram</span></Link></li>
                                    
                               </ul>

                           </div>

                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><span>Aave Protocol</span></Link></li>
                                   <li><Link to="/Home"><span>Developers</span></Link></li>
                                   <li><Link to="/Home"><span>Security</span></Link></li>
                                   <li><Link to="/Home"><span>aTokens</span></Link></li>
                                   <li><Link to="/Home"><span>Bug Bounty</span></Link></li>
                                   <li><Link to="/Home"><span>Flash Loans</span></Link></li>                                    
                               </ul>

                           </div>

                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><span>Rate Switching</span></Link></li>
                                   <li><Link to="/Home"><span>Whitepaper</span></Link></li>
                                   <li><Link to="/Home"><span>Aavenomics</span></Link></li>
                                   <li><Link to="/Home"><span>Ecosystem</span></Link></li>
                                   <li><Link to="/Home"><span>Careers</span></Link></li>
                                   <li><Link to="/Home"><span>Branding</span></Link></li>                                    
                               </ul>

                           </div>

                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><span>Rate Switching</span></Link></li>
                                   <li><Link to="/Home"><span>Whitepaper</span></Link></li>                                                                     
                               </ul>
                               <div className="subscribe_sec">
                                   <p>Subscribe</p>
                                   <input type="text" className="form-control" placeholder="Enter the Email"></input>
                                   <Link to="/Home"><img src={buttomimage} /></Link>
                               </div>

                           </div>
                   </div> */}

               </div>
               </div>
               <div className="footer_sec_2">
               <div className="container conatiner_custom">
                   <p className="mb-0 text-center footer_text mt-3 mb-0">Copyright © 2021 . <span className="text_yelw">All Rights Reserved</span></p>
                   </div>
               </div>
               </div>
       
           </div>
            {/* disclaimer */}
            <Modal
                                show = {this.state.disclaimerModalShow}
                                onHide = {disclaimerModalClose}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                   Disclaimer
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <p className="modal_dis_p">TostSwap is a decentralized peer-to-peer app that people can use to create liquidity and trade BEP-20 tokens. Your use of the TostSwapapp involves various risks, including, but not limited to, losses while digital assets are being supplied to the TostSwapapp and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the TostSwapapp, you should review the relevant documentation to make sure you understand how the TostSwapapp works. Additionally, just as you can access email apps such as SMTP through multiple email clients, you can access the TostSwapapp through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.</p>
                                <p className="modal_dis_p mb-0">No developer or entity involved in creating the TostSwap app will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the TostSwap app, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.By using the tostswap application, you bear YOUR OWN RISK, AND WITHOUT WARRANTY OF ANY KIND.</p> 
                                </Modal.Body>
                              
                                </Modal>
                                         {/* disclaimer */}
           </div>
        );
    }
}

export default Footer