import React, { Component } from 'react';
import Headerinner from './Headerinner';
import Sidebar from './Sidebar';
import Navbarexchange from './Navbarexchange';

// import { Tabs, Tab } from 'react-bootstrap';

import { Modal, Button } from 'react-bootstrap';



// import '../css/footer.css';
import '../css/exchange.css';

import ok from "../images/ok.png"

import refresh from '../images/refresh.png'

import bnb from '../images/bnb.png'
import ada from '../images/ada.png'
import auto from '../images/auto.png'

import alpha from '../images/alpha.png'
import ankr from '../images/ankr.png'
import asr from '../images/asr.png'
import atm from '../images/atm.png'
import atom from '../images/atom.png'
import bake from '../images/bake.png'
import balbt from '../images/balbt.png'
import band from '../images/band.png'

import exchangearrow from '../images/exchage-down-arrow.png'



class Liquidity extends Component 
{ 
    constructor(props)
    {
        super(props);
        this.state = {settingModalShow: false,
                        transactionModalShow: false,
                        coinModalShow: false}
       


    }

    componentDidMount()
    {
        document.getElementById("liquidity_btn").classList.add('active');
        document.getElementById("exchange_btn").classList.remove('active');
        document.getElementById("example-collapse-text").classList.add("show");
    }
  
    showLiquidity()
    {
        document.getElementById("liquidity_main").classList.remove('d-block');
        document.getElementById("liquidity_main").classList.add('d-none');
        // document.getElementById("add_liquidity").classList.remove('d-block');
        document.getElementById("add_liquidity").classList.add('d-block');

        
    }
    showImport()
    {
        document.getElementById("liquidity_main").classList.remove('d-block');
        document.getElementById("liquidity_main").classList.add('d-none');
        // document.getElementById("add_liquidity").classList.remove('d-block');
        document.getElementById("import").classList.add('d-block');
    }
    showMain()
    {
        document.getElementById("add_liquidity").classList.remove('d-block');

        document.getElementById("add_liquidity").classList.add('d-none');
        document.getElementById("import").classList.remove('d-block');

        document.getElementById("import").classList.add('d-none');
        document.getElementById("liquidity_main").classList.add('d-block');
    }
   
   
    render() {
      // console.log(this.props.location.pathname);
      const location = this.props.location.pathname.split('/')[1];
      let settingModalClose = () => this.setState({settingModalShow:false})
      let transactionModalClose = () => this.setState({transactionModalShow:false})
      let coinModalClose = () => this.setState({coinModalShow:false})


		return (
            <div>
               <Headerinner />
               <div className="row mx-0 main_row">
                   <div className="col-12 col-lg-3 col-lg-3-sidebar px-0 sidebar_col">
                    <Sidebar location={location}/>
                   </div>
                   <div className="col-12 col-lg-9 col-lg-9-custom main_col  mt-5">
                       <div className="container container_custom bg_back py-5">
                        <div className="row mx-0">
                            <div className="col-12 col-lg-8 col-xl-6 mx-auto">
                                <div className="exchange_tab">
                                    <Navbarexchange />
                                {/* <button className="btn_tab">Bridge</button> */}

                                <div className="my-5">
                                        {/* liquidity main */}
                                    <div className="tab_card card" id="liquidity_main">
                                        <div className="card-header">
                                            <div className="row mx-0">
                                                <div className="col-12 col-md-8">
                                        <h2 className="tab_heading">Liquidity</h2>
                                            <p className="tab_text mb-0">Add liquidity to receive LP tokens</p>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="flex_img">
                                                    <span className="mr-3">
                                                <img src={ok} className="cusror_pointer" onClick={() => this.setState({settingModalShow:true})}/>
                                                </span>
                                                <span className="">
                                                <img src={refresh}  className="cusror_pointer" onClick={() => this.setState({transactionModalShow:true})}/>
                                                </span>
                                                </div>

                                            </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="btn btn_lg_purple" onClick={this.showLiquidity}>Add Liquidity</button>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                        <p className="text_2_head">Your Liquidity</p>
                                        <div className="p-4">
                                        <p className="text_grey_big text-center">Connect to a wallet to view your liquidity.</p>
                                        </div>
                                        <div>
                                            <p>
                                                <span className="text_purple">Don't see a pool you joined?</span>
                                                <span className="text_a cursor_pointer pl-2" onClick={this.showImport}>Import it.</span>
                                                </p>
                                                <p>
                                                <span className="text_purple">Or, if you staked your FLIP tokens in a farm, unstake them to see them here.</span>
                                                </p>
                                        </div>
                                           
                                           
                                        </div>
                                    </div>
                                    {/* add_liquidity */}
                                    <div className="tab_card card" id="add_liquidity">
                                        <div className="card-header">
                                            <div className="row mx-0">
                                                <div className="col-12">
                                        <h2 className="tab_heading">
                                            <span className="text-center"><i className="fa fa-arrow-left pr-3 cursor_pointer" aria-hidden="true" onClick={this.showMain}></i>
                                            </span>Add Liquidity</h2>
                                           
                                            </div>
                                           
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="card card_inner">
                                                <div className="card-body">
                                                <p>Input</p>
                                                <p className="mb-0">
                                                    <input value="0.0" className="input_transparent" /> 
                                                    <span className="cursor_pointer dropdown_span float-right"  onClick={() => this.setState({coinModalShow:true})}>
                                                        <img src={bnb} className="dropdown_img" />
                                                        <span>BNB<i className="fa fa-angle-down pl-2" aria-hidden="true"></i></span>
                                                    </span>
                                                    </p>
                                                    </div>
                                            </div>
                                            <div className="text-center py-4">
                                            <i className="fa fa-plus font_big" aria-hidden="true"></i>
                                            </div>
                                            <div className="card card_inner">
                                                <div className="card-body">
                                                <p>Input</p>
                                                <p className="mb-0">
                                                    <input value="0.0" className="input_transparent" /> 
                                                    <span className="cursor_pointer dropdown_span float-right"  onClick={() => this.setState({coinModalShow:true})}>
                                                        
                                                        <span>Select a token<i className="fa fa-angle-down pl-2" aria-hidden="true"></i></span>
                                                    </span>
                                                    </p>
                                                    </div>
                                            </div>
                                            <div className="text-center pt-4">
                                            <a href="/uniswapapp" className="get-started-btn btn_big">Unlock Wallet</a>
                                            </div>
                                           
                                        </div>
                                    </div>

                                          {/* import */}
                                          <div className="tab_card card" id="import">
                                        <div className="card-header">
                                            <div className="row mx-0">
                                                <div className="col-12">
                                        <h2 className="tab_heading">
                                            <span className="text-center"><i className="fa fa-arrow-left pr-3 cursor_pointer" aria-hidden="true" onClick={this.showMain}></i>
                                            </span>Import Pool</h2>
                                           
                                            </div>
                                           
                                            </div>
                                        </div>
                                        <div className="card-body">
                                                <p className="mb-0">
                                                    
                                                    <span className="cursor_pointer dropdown_span_1"  onClick={() => this.setState({coinModalShow:true})}>
                                                        <img src={bnb} className="dropdown_img" />
                                                        <span>BNB<i className="fa fa-angle-down pl-2" aria-hidden="true"></i></span>
                                                    </span>
                                                    </p>
                                            <div className="text-center py-4">
                                            <i className="fa fa-plus font_big" aria-hidden="true"></i>
                                            </div>
                                           
                                              
                                                <p className="mb-0">
                                                    
                                                    <span className="cursor_pointer dropdown_span_1"  onClick={() => this.setState({coinModalShow:true})}>
                                                        
                                                        <span>Select a token<i className="fa fa-angle-down pl-2" aria-hidden="true"></i></span>
                                                    </span>
                                                    </p>

                                                    <div className="text-center pt-4">
                                                        <p className="text_purple font_weight_600">Connect to a wallet to find pools</p>
                                                    </div>
                                                 
                                           
                                           
                                        </div>
                                    </div>

                                    
                                                            
                                    </div>

                                    {/* modal */}
                                    {/* settings */}
                                    <Modal
                                show = {this.state.settingModalShow}
                                onHide = {settingModalClose}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                   Settings
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="text_1_purple">Slippage tolerance</p>
                                    <div className="badge_sec">
                                        <span>
                                            <badge className="badge">0.1%</badge>
                                        </span>
                                        <span>
                                            <badge className="badge">0.5%</badge>
                                        </span>
                                        <span>
                                            <badge className="badge">1%</badge>
                                        </span>
                                        <span className="d-flex align-items-center mt-3 mt-sm-0">
                                            <input className="form-control"/><span className="pl-3 text_purple font_weight_600">%</span>
                                        </span>
                                    </div>
                                    <p className="text_1_purple mt-4">Transaction deadline</p>
                                    <div className="badge_sec">
                                    <span className="d-flex align-items-center">
                                            <badge className="badge badge_pink">20</badge>
                                            <span className="pl-2 text_purple font_weight_600">Minutes</span>
                                        </span>
                                        </div>
                                </Modal.Body>
                              
                                </Modal>
                                         {/* settings */}
                                {/* transaction */}
                            
                                 <Modal
                                show = {this.state.transactionModalShow}
                                onHide = {transactionModalClose}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                   Recent Transactions
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                   <div className="text-center">
                                       <p className="text_big_center mb-4">Please connect your wallet to view your recent transactions</p>
                                       <Button className="btn btn_close" onClick={transactionModalClose}>Close</Button>
                                   </div>
                                </Modal.Body>
                              
                                </Modal>

                                 {/* coin */}
                            
                                 <Modal
                                show = {this.state.coinModalShow}
                                onHide = {coinModalClose}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                   Select a Token
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                   <div className="text-center">
                                      <input className="form-control search_form" placeholder="Search name or paste address" />
                                      </div>
                                   <div className="mt-3">
                                   
                                       <p className="text_purple">
                                           <span>Token Name</span>
                                           <span className="float-right"><i className="fa fa-arrow-down" aria-hidden="true"></i></span>
                                           
                                       </p>
                                       <div className="mt-3">
                                           <ul className="coin_ul">
                                               <li>
                                                   <img src={bnb} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">BNB</span>
                                               </li>
                                               <li>
                                                   <img src={ada} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">ADA</span>
                                               </li>
                                               <li>
                                                   <img src={alpha} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">ALPHA</span>
                                               </li>
                                               <li>
                                                   <img src={ankr} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">ANKR</span>
                                               </li>
                                               
                                               <li>
                                                   <img src={asr} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">ASR</span>
                                               </li>
                                               <li>
                                                   <img src={atm} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">ATM</span>
                                               </li>
                                               <li>
                                                   <img src={atom} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">ATOM</span>
                                               </li>
                                               <li>
                                                   <img src={auto} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">AUTO</span>
                                               </li>
                                               <li>
                                                   <img src={bake} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">BAKE</span>
                                               </li>
                                               <li>
                                                   <img src={balbt} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">bALBT</span>
                                               </li>
                                               <li>
                                                   <img src={band} className="dropdown_img" />
                                                   <span className="text_purple font_weight_600">BAND</span>
                                               </li>

                                           </ul>
                                       </div>
                                   </div>
                                  
                                </Modal.Body>
                              
                                </Modal>
                                </div>

                            </div>
                        </div>
                    </div>
                   </div>
               </div>
            </div>
        )
        }
}

export default Liquidity