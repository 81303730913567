import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import { Modal, Button, Dropdown } from 'react-bootstrap';

import '../css/header.css';
import logo from "../images/logo.png"
import logoIcon from "../images/logo-icon.png"
import logoText from "../images/logo-text-black.png"
import dark from "../images/dark.png"
import bright from "../images/bright.png"
import globe from "../images/globe.png"
class Header extends Component 
{

    darkTheme()
    {
        document.body.classList.remove('light_theme'); 
        document.body.classList.add('dark_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoWhite;
    }
    lightTheme()
    {
        document.body.classList.remove('dark_theme'); 
        document.body.classList.add('light_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoInner;
    }
      
    showMobile()
    {
        document.getElementById("mobile_nav_item").classList.toggle('active');
        document.getElementById("mobileLayer").classList.toggle('active');
        document.getElementById("burger").classList.toggle('clicked');       
        
    }
   
    
      
    
   constructor(props)
   {
       super(props);
       this.state = {location:false}
   }
  
   componentDidMount()
   { 
    document.body.classList.add('dark_theme'); 
    // const location = this.props.location.pathname.split('/')[1];
    // console.log("location",window.location.pathname.split('/')[1]);
    // const currentLocation = window.location.pathname.split('/')[1];
    // if(currentLocation == 'Home')
    // {
    //     window.addEventListener('scroll', function() {
    //         if(window.pageYOffset >100)
    //         {
    //             document.getElementById("header").classList.add('header-scrolled');
    //         }
    //         else{
    //             document.getElementById("header").classList.remove('header-scrolled');
 
    //         }
    //     });
    // }
    // console.log(this.state);
           
   }
    render() {
    const {location} = this.props;


		return (
           <div>
               <div id="mobileLayer">
                   <div id="header" className="fixed-top-navbar header-scrolled py-2">
               <div className="container container_custom" id="mobileshow">
               <header className={`${location}`}>

<div className="d-flex align-items-center">

    {/* <h1 className=""> */}
        {/* <a href="/"> */}
        {/* <img src={logo} /> */}
        <span className="logo_full mr-auto">
        {/* <img src={logoIcon} className="logo_icon" id="logo_inner"/>
        <img src={logoText} className="logo_text" id="logo_inner_text"/> */}

        </span>
        {/* </a> */}
        {/* </h1>        */}

    <nav className="nav-menu d-none d-lg-block">
          <ul>
          <li className="index-header-link"><a href="https://app.tostswap.finance/">Home</a></li>
            <li className="index-header-link"><a href="https://app.tostswap.finance/pools">Pool</a></li>
            <li className="index-header-link"><a href="https://app.tostswap.finance/farms">Farming</a></li>
            <li className="index-header-link"><a href="#info_section">Info</a></li>
            <li className="">
            <img src={bright} onClick={this.lightTheme}  className="cursor_pointer icon_black"/>                   
            <span className="px-2 text-white slash_icon">/</span>
            <img src={dark} onClick={this.darkTheme} className="cursor_pointer icon_black" />
            </li>
            <li className="nav-item d-flex">
                    <span className="text_purple pl-2 dr_span dr_span_landing">
                <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic-1" className="pl-2">
                <img src={globe} className="mr-2 icon_black" /> EN
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item>English</Dropdown.Item>
                <Dropdown.Item>Chinese</Dropdown.Item>
                <Dropdown.Item>Korean</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                </span>
            </li>

            <a href="https://app.tostswap.finance/" className="get-started-btn" data-target="landcontact" id="reques"><i className="fa fa-external-link mr-2" aria-hidden="true"></i>USE APP</a>
        
        </ul>
    </nav>
    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={this.showMobile}><i className="fa fa-bars" aria-hidden="true" id="burger"></i></button>

    <nav className="mobile-nav d-lg-none mt-5" id="mobile_nav_item">
              <ul>
               
              <li className="index-header-link"><a href="https://app.tostswap.finance/">Home</a></li>
            <li className="index-header-link"><a href="https://app.tostswap.finance/pools">Pool</a></li>
            <li className="index-header-link"><a href="https://app.tostswap.finance/farms">Farming</a></li>
            <li className="index-header-link"><a href="#info_section">Info</a></li>
            <li className="pl-3 mb-2">
            <img src={bright} onClick={this.lightTheme}  className="cursor_pointer icon_black"/>                   
            <span className="px-2 text-white slash_icon">/</span>
            <img src={dark} onClick={this.darkTheme} className="cursor_pointer icon_black" />
            </li>
            <li className="nav-item d-flex pl-3">
                    <span className="text_purple  dr_span dr_span_landing">
                <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic-1" className="pl-2">
                <img src={globe} className="mr-2 icon_black" /> EN
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item>English</Dropdown.Item>
                <Dropdown.Item>Chinese</Dropdown.Item>
                <Dropdown.Item>Korean</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                </span>
            </li>

           
                <a href="https://app.tostswap.finance/" className="get-started-btn my-3"><i className="fa fa-external-link mr-2" aria-hidden="true"></i>USE APP</a>
            
            </ul>
        </nav>

</div>


</header>
</div>
</div>
</div>

           </div>
        );
    }
}



export default Header