import React, { Component } from 'react';
import Headerinner from './Headerinner';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import { Button, Collapse } from 'react-bootstrap';

// import '../css/footer.css';
import '../css/pool.css';

import pink from "../images/pin.png"

import boximghome from '../images/farm-box.png'
import ok from "../images/ok.png"
import arrdown from "../images/arrow-down.png"
import cakecat from "../images/cakecat.png"


class Farms extends Component 
{ 
    constructor(props, context) {
		super(props, context);

		this.state = {
			open: false,
            openone:false,
            opentwo:false,
            openthree:false,
            openfour:false,
            openfive:false,
            opensix:false,
            opencomunity:false,
            openseven:false,
            openeight:false,
            opennine:false,
            openten:false,
            openeleven:false,


		};
	}

    showActive()
    {
        document.getElementById("inactive_btn").classList.remove('active');
        document.getElementById("active_btn").classList.add('active');
        // document.getElementById("add_liquidity").classList.remove('d-block');
        document.getElementById("inactive_content").classList.remove('d-block');
        document.getElementById("inactive_content").classList.add('d-none');
        document.getElementById("active_content").classList.remove('d-none');
        document.getElementById("active_content").classList.add('d-block');

    }
    showInactive()
    {
        document.getElementById("active_btn").classList.remove('active');
        document.getElementById("inactive_btn").classList.add('active');
        // document.getElementById("add_liquidity").classList.remove('d-block');
        document.getElementById("active_content").classList.add('d-none');
        document.getElementById("active_content").classList.remove('d-block');

        document.getElementById("inactive_content").classList.add('d-block');
        document.getElementById("inactive_content").classList.remove('d-none');


    }
   
    render() {
        const { open,openone,opentwo,openthree,openfour,openfive, opensix, opencomunity, openseven,openeight, opennine, openten, openeleven } = this.state;
      // console.log(this.props.location.pathname);
      const location = this.props.location.pathname.split('/')[1];

		return (
            <div>
               <Headerinner />
               <div className="row mx-0 main_row">
                   <div className="col-12 col-lg-3 col-lg-3-sidebar px-0 sidebar_col">
                    <Sidebar location={location}/>
                   </div>
                   <div className="col-12 col-lg-9 col-lg-9-custom main_col mt-lg-custom">
                       <div className="container container_custom pt-5">
                        <div className="row mx-0">
                            <div className="col-12">
                                <h1 className="pool_heading_1 text-center">Stake LP tokens to earn DOUGHNUT</h1>                               
                            </div>                           
                        </div>
                        <div className="nav_sec">
                        <span>
                      
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                            <label className="custom-control-label" for="customSwitch1">Staked only</label>
                            </div>
                        </span>
                        <span>
                            <div className="tab_sec_custom">
                            <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-secondary active" id="active_btn"  onClick={this.showActive}>Active</button>
                        <button type="button" className="btn btn-secondary"  id="inactive_btn" onClick={this.showInactive}>Inactive</button>
                       
                        </div>
                            </div>
                            
                        </span>
                        </div>

                        <div className="tab_content_custom">
                                <div className="active_content" id="active_content">
                                    <div className="py-4">
                                    <div className="row mx-0  justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>40X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>146.99%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ open: !open })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.open}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>40X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>146.99%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openone: !openone })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openone}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>40X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>146.99%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ opentwo: !opentwo })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.opentwo}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>40X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>146.99%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openthree: !openthree })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openthree}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>40X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>146.99%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openfour: !openfour })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openfour}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>40X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>146.99%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openfive: !openfive })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openfive}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        

                                      
                                    </div>
                                    <div className="row mx-0 justify-content-center">
                                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                    <i className="fa fa-users mr-2 user_grp_icon" aria-hidden="true"></i> 
                                                        <span>Community</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>0.1X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">APR</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right"><i class="fa fa-file-text pr-2" aria-hidden="true"></i>362.93%</p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ opencomunity: !opencomunity })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.opencomunity}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                                Total Liquidity</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">$597,974,389</p>
                                                                </div>
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inactive_content" id="inactive_content">
                                <div className="py-4">
                                <div className="row mx-0 justify-content-center">
                                <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>0X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openseven: !openseven })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openseven}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">                                                        
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>0X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openeight: !openeight })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openeight}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">                                                        
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>0X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ opennine: !opennine })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.opennine}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">                                                        
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>0X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openten: !openten })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openseven}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">                                                        
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                                            <div className="card card_pool">
                                                <div className="background_shadow"></div>
                                                <div className="card-body">
                                                    <div className="flex_text_img">
                                                    <img src={boximghome} />
                                                    <span>
                                                    <h2 className="pool_head text-right">Dough Pool</h2>
                                                    <div className="d-flex">
                                                    <badge className="badge_purple mr-2">
                                                        <img src={ok}  className="mr-2" />
                                                        <span>Core</span>
                                                    </badge>
                                                    <badge className="badge_purple_fill">                                                      
                                                        <span>0X</span>
                                                    </badge>
                                                    </div>
                                                    </span>
                                                    </div>
                                                    
                                                                <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">
                                                                <span className="font_icon"></span>
                                                               Earn</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">CAKE</p>
                                                                </div>
                                                                </div>
                                                    
                                                    <p className="pool_price_subtext pool_price_subtext_big">Nuts <span className="pool_tetx_grey">Earned</span></p>
                                                    <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool_disable menu_title_pool_disable_big">
                                                                <span className="font_icon mr-2"></span>
                                                               0</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0 text-sm-right">
                                                               <button className="btn btn_harvest">Harvest</button>
                                                                </div>
                                                                </div>
                                                                <p className="pool_price_subtext pool_price_subtext_big text-uppercase">Nuts-BNB LP <span className="pool_tetx_grey text-uppercase">Straked</span></p>
                                                    <div className="text-center">
                                                    <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                                    </div>
                                                   
                                                                                                        </div>
                                                            <div className="card-footer">
                                                                <div className="row mx-0">
                                                                    
                                                                    <div className="col-12 text-center">
                                                                    <span
                                                                    onClick={() => this.setState({ openeleven: !openeleven })}
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={open}
                                                                >
                                                        <div className="d-flex align-items-center justify-content-center">

                                                        <span className="sidebar_text cursor_pointer">Details<img src={arrdown} className="down_icon pl-2" /></span>
                                                        </div>
                                                        </span>
                                                                        
                                                        </div>
                                                        <div className="col-12">
                                                        <Collapse in={this.state.openeleven}>
                                                        <div id="example-collapse-text">
                                                        <div className="row mx-0 align-items-center mt-3">
                                                        <div className="col-12 col-sm-6 px-0">
                                                            <p className="menu_title_pool menu_title_pool_big">Deposit</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6 px-0">
                                                                <p className="menu_title_1_pool menu_title_1_pool_big text-sm-right">
                                                                    CAKE-BNB LP<i class="fa fa-external-link ml-2" aria-hidden="true"></i></p>
                                                                </div>
                                                                </div>
                                                                <div className="row mx-0 align-items-center">                                                        
                                                                <div className="col-12 px-0">
                                                                    <a href="#" className="a_purple">View on BscScan</a>
                                                                </div>
                                                                </div>
                                                        </div>
                                                        </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                     
                                        </div>
                                </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <img src={cakecat} className="img-fluid" />
                            </div>
                        
                    </div>
                   </div>
               </div>
            </div>
        )
        }
}

export default Farms