import React, { Component } from 'react';
import Headerinner from './Headerinner';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

// import '../css/footer.css';
import '../css/home.css';

import homeBurger from "../images/home.png"

import boximghome from '../images/boximg-home.png'
import lottery from '../images/lottery.png'
import arrow from '../images/arrow.png'


class Uniswapapp extends Component 
{ 
    
   
    render() {
      // console.log(this.props.location.pathname);
      const location = this.props.location.pathname.split('/')[1];

		return (
            <div>
               <Headerinner />
               <div className="row mx-0 main_row">
                   <div className="col-12 col-lg-3 col-lg-3-sidebar px-0 sidebar_col">
                    <Sidebar location={location} />
                   </div>
                   <div className="col-12 col-lg-9 col-lg-9-custom main_col mt-lg-5">
                       <div className="container container_custom">
                    <div className="py-5">
                        <div className="d-block d-lg-none text-center">
                            <img src={homeBurger} className="img-fluid mb-3" />
                        </div>
                        <div className="div_title">
                        <h1 className="text_purple text-center font_weight_600">Doughnut Swap</h1>
                        <p className="text_black font_18 text-center">Lorem ipsum, or lipsum as it is sometimes known,</p>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-md-6 mb-4">
                            <div className="card card_home card_home_1">
                                <div className="card-body">
                                    <h2 className="text_purple">Frams & Stacking</h2>
                                    <img src={boximghome} />
                                    <div>
                                        <p className="pdt_subtitle">Locked</p>
                                        <p className="pdt_title">Doughnut to Harvest:</p>
                                        <p className="pdt_subtitle">Locked</p>
                                        <p className="pdt_title">Doughnut to Harvest:</p>
                                        <Link to="/uniswapapp" className="get-started-btn btn_inner_1 btn-block">Unlock Wallet</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <div className="card card_home card_home_2">
                                <div className="card-body">
                                    <h2 className="text_purple">Your Lottery Winnings</h2>
                                    <img src={lottery} />
                                    <div>
                                        <p className="pdt_subtitle_purple">0.000</p>
                                        <p className="pdt_title">Doughnut to Harvest:</p>
                                        <p className="pdt_subtitle_purple">56,88,92</p>
                                        <p className="pdt_title">Doughnut to Harvest:</p>
                                        <div className="row mx-0">
                                            <div className="col-12 col-md-6 px-0">
                                        <Link to ="/uniswapapp" className="btn_grey_1 mr-2">Collect Winnings</Link>

                                            </div>
                                            <div className="col-12 col-md-6 px-0">
                                            <Link to ="/uniswapapp" className="btn_grey_1 mr-2">Buy Tickets</Link>
                                                
                                                </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mx-0">
                        <div className="col-12 col-lg-4 mb-4">
                        <div className="card card_home card_style_1">
                    <div className="card-body">
                        <h2 className="mb-0">Earn up to</h2>
                        <h3>2,467.60%
                            <br />APR</h3>
                        <h2 className="mb-0">in Farms</h2>
                        <div className="float-right">
                            <img src={arrow} />
                        </div>

                    </div>
                    </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-4">
                        <div className="card card_home card_style_1">
                    <div className="card-body">
                        <h2 className="mb-0">Earn up to</h2>
                        <h3>Doughnut,

                            <br />sfp, Lina</h3>
                        <h2 className="mb-0">in Pools</h2>
                        <div className="float-right">
                            <img src={arrow} />
                        </div>

                    </div>
                    </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-4">
                        <div className="card card_home card_style_1">
                    <div className="card-body">
                        <h2 className="mb-0">Lottery with</h2>
                        <h3>2,467.60%
                            </h3>
                        <h2 className="mb-0">up for grabs</h2>
                        <div className="float-right">
                            <img src={arrow} />
                        </div>

                    </div>
                    </div>
                        </div>
                    </div>

                    <div className="row mx-0">
                        <div className="col-12 col-lg-6 mb-4">
                        <div className="card card_home card_style_1">
                    <div className="card-body">
                        <h3>Cake Stats</h3>
                        <div className="row mx-0 align-items-center mt-3">
                            <div className="col-12 col-sm-6 px-0">
                            <p className="menu_title">Total Dhugnut Supply</p>
                            </div>
                            <div className="col-12 col-sm-6 px-0">
                            <p className="menu_title_1 text-sm-right">178,25253,878</p>
                            </div>
                        </div>
                        <div className="row mx-0 align-items-center">
                            <div className="col-12 col-sm-6 px-0">
                            <p className="menu_title">Total Dhugnut Supply</p>
                            </div>
                            <div className="col-12 col-sm-6 px-0">
                            <p className="menu_title_1 text-sm-right">0.000</p>
                            </div>
                        </div>
                        <div className="row mx-0 align-items-center">
                            <div className="col-12 col-sm-6 px-0">
                            <p className="menu_title mb-3 mb-sm-0">New Dhugnut / block</p>
                            </div>
                            <div className="col-12 col-sm-6 px-0">
                            <p className="menu_title_1 text-sm-right mb-0">25</p>
                            </div>
                        </div>
                            
                        

                    </div>
                    </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-4">
                        <div className="card card_home card_style_1 h-100">
                    <div className="card-body">
                        <h4>Total value locked (TVL)</h4>
                        <h3>$1,422,681,659
                            </h3>
                        <p className="mb-0 text_purple font_14">Across all LPs and Syrup Pools</p>
                       

                    </div>
                    </div>
                        </div>
                        
                        </div>
                        
                    </div>
                   </div>
               </div>
            </div>
        )
        }
}

export default Uniswapapp