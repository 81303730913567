import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import './App.css';

import Landing from './components/Landing';
import Uniswapapp from './components/Uniswapapp';
import Exchange from './components/Exchange';
import Liquidity from './components/Liquidity';
import Farms from './components/Farms';
import Pool from './components/Pool';

import Innerhome from './components/Innerhome';


function App() {
  return (
    <div>  
      <Router basename={'/'}>
	      <Route exact path='/' component={Landing} />
	      <section className='container-fluid px-0'>
	      	<Switch>
            <Route exact path='/Home' component={Landing} />
            <Route exact path='/uniswapapp' component={Uniswapapp} />	
            <Route exact path='/Exchange' component={Exchange} />	   	
            <Route exact path='/Liquidity' component={Liquidity} />	
            <Route exact path='/Farms' component={Farms} />
            <Route exact path='/Pools' component={Pool} />	

            <Route exact path='/Swap' component={Innerhome} />	

	      	</Switch>
	      </section>
      </Router>
    </div>
  );
}

export default App;
