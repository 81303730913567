import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbarexchange extends Component 
{ 
    render() {
        return (
            <div className="text-center">
            <div className="tab_sec_custom exchange_tab">
            <div className="btn-group" role="group" aria-label="Basic example">
            <Link to = '/Exchange'><button className="btn" id="exchange_btn">Exchange</button></Link>
            <Link to  = '/Liquidity'><button className="btn" id="liquidity_btn">Liquidity</button></Link>

            </div>
            </div>
            </div>
        )
    }
}
export default Navbarexchange